import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import MetaTags from '../../components/MetaTags';
import { theme } from '../../styles/theme';
import Background from '../../components/Background';
import Header from '../../components/Header2';
import HowGroupGiftsWork from '../../sections/occasion/HowGroupGiftsWork';
import CuratedGifts from '../../sections/occasion/CuratedGifts';
import GroupCard from '../../sections/occasion/GroupCard';
import LoveIllume from '../../sections/occasion/LoveIllume';
import JoinOccasions from '../../sections/JoinOccasions';
import Footer from '../../sections/common/FooterWithNav';
import data from '../../sections/occasion/data';
import HeaderSection from '../../sections/occasion/HeaderSection';
import futureCelebrating from '../../images/occasion/baby-shower.png';
import futurePhone from '../../images/occasion/iPhone12.png';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const OccassionBabyShower: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='Occasions | illume'
        description={'Create memorable group gifts for baby showers with illume.'}
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <HeaderSection
          heading={'Group Gifts for Baby Showers'}
          paragraphs={[
            'Amanda can’t make it to Maria’s baby shower. Since she’s not the only one who can’t be there, she organizes a group celebration. Even at a distance, they can celebrate the newborn with a group gift and ecard from illume!',
            'They pick out a unique gift from illume’s digital marketplace, contribute with personal messages, photos, and more – and then hit send when they’re ready.',
          ]}
          buttonLink={
            'https://web.illumenotes.com/receive?c=ebbd042e-6749-42e5-8e91-289b50229ce6&email=email@tryillume.com'
          }
          images={{
            celebration: {
              image: futureCelebrating,
              label: 'Baby Shower',
            },
            phone: {
              image: futurePhone,
              label: 'Baby Shower Mobile',
            },
          }}
        />
        <HowGroupGiftsWork />
        <CuratedGifts gifts={data.gifts} />
        <GroupCard />
        <LoveIllume testimonials={data.testimonials} />
        <JoinOccasions joinMessage={'of celebrating milestones'} />
        <Footer links={data.links} />
      </Layout>
    </ThemeProvider>
  );
};

export default OccassionBabyShower;
